import React from "react";
import "./NewsLetter.css";

const NewsLetter = () => {
  return (
    <div className="newsletter">
      <div className="form-container">
        <h3>Connect with Us</h3>
        <p>For updates about retreat dates, workshops, training and more</p>
        <form action="" className="news-letter__form">
          <input type="email" placeholder="Email" />
          <input type="submit" value="Subscribe" />
        </form>
      </div>
    </div>
  );
};

export default NewsLetter;
