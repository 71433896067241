import React, { useEffect, useState } from "react";
import axios from "axios";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import "./WealiScreen.css";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import Testimonial from "../components/HomeComponents/Testimonial";
import Enquireform from "../components/EnquireForm/Enquireform";

function WealiScreen() {
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(
        "https://adminoncountry.murphysdemo.com.au/api/wealimage"
      );
      console.log("API response:", response.data); // Log the API response
      if (Array.isArray(response.data.urls)) {
        setImageURLs(response.data.urls);
      } else {
        console.error(
          "Image URLs are not in the expected format:",
          response.data.urls
        );
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };
  return (
    <>
      <section className="weali__section">
        <BreadCrumb title={"We Al-li"} />
        <div className="container" style={{ padding: "8em 0 6em 0" }}>
          <div className="weali__text">
            <div className="text__section">
              <h2>We Al-Li</h2>
              <p>
                We Al-li Pty Ltd is an Australian company that conducts training
                workshops to educate participants in meeting the demands of
                working with Aboriginal and Torres Strait Islander peoples and
                communities, but is also relevant to non-Indigenous peoples.
              </p>
            </div>

            <p>
              We Al-li is a culturally informed, trauma integrated healing
              educational and practice-based approach that promotes health,
              well-being, and sustainable pathways of positive change for
              individuals, families and communities at both a personal and
              professional level.
            </p>

            <p>
              The workshops run by We Al-li address trauma and healing and
              assist individuals, families, organisations and communities
              towards recovery.{" "}
            </p>

            <p>
              Jem Stone, founder of On Country Connections has been trained by
              We Al-li Pty Ltd to facilitate the workshops and is licensed under
              the We Al-li Sovereign Business Model to conduct workshops and
              training.
            </p>

            <p>
              Contact us below to learn more about how your wellness journey can
              be supported by We Al-li.
            </p>
          </div>
        </div>
        <div className="weali__images">
          {imageURLs.map((url, index) => (
            <div className="weali__image" key={index}>
              <img src={url} alt={`Consultant ${index + 1}`} />
            </div>
          ))}
        </div>
        {/* <div className="container" style={{ paddingTop: "2em " }}>
          <div className="weali__text">
            <div className="text__section"></div>
          </div>
        </div> */}
      </section>
      <Enquireform />
      <Testimonial />
    </>
  );
}

export default WealiScreen;
