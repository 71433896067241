import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1b63g3p",
        "template_rc1s6x7",
        form.current,
        "9upcAZzBw0CEVWwXP"
      )
      .then(
        (result) => {
          alert(
            "Your message has been sent successfullt. We will get back to you soon."
          );
        },
        (error) => {
          alert("Something went wrong. Please try again later");
        }
      );
  };
  return (
    <>
      <BreadCrumb title={"Contact Us"} />
      <section className="contact__section">
        <div className="container">
          <h3>Register your interest</h3>
          <span>
            <p>Retreats</p>
            <p>|</p>
            <p>Wellness</p>
            <p>|</p>
            <p>Training</p>
            <p>|</p>
            <p>Events</p>
          </span>
          <p>
            Please fill in this form to register your interest and I'll be in
            touch soon!
          </p>
          <div className="form-section">
            <form action="" ref={form} onSubmit={sendEmail}>
              <div className="form--grid">
                <div className="input-group">
                  <input type="text" placeholder="First Name *" name="fname" />
                </div>
                <div className="input-group">
                  <input type="text" placeholder="Last Name *" name="lname" />
                </div>
                <div className="input-group">
                  <input
                    type="email"
                    placeholder="Email Address *"
                    name="email"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Phone Number *"
                    name="phone"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Organization Name"
                    name="organization"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="date"
                    placeholder="Select Event Date"
                    name="date"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Number of Participants"
                    name="numberOfParticipants"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Event Name"
                    name="event_name"
                  />
                </div>
              </div>

              <div className="input-group">
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Send a message"
                ></textarea>
              </div>
              <div className="primary-btn" style={{ marginTop: "1em" }}>
                <a href="#!">Submit</a>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d209190.24272546108!2d117.59160051445971!3d-34.99174430045948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a391986cdb08f67%3A0x400f6382479c070!2sAlbany%20WA%206330%2C%20Australia!5e0!3m2!1sen!2snp!4v1694502579984!5m2!1sen!2snp"
          width="600"
          height="450"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="Location"
        ></iframe>
      </div> */}
    </>
  );
}

export default Contact;
