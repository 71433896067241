import React, { useRef } from "react";
import "../../screens/Contact.css";
import emailjs from "@emailjs/browser";

function Enquireform() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1b63g3p",
        "template_rc1s6x7",
        form.current,
        "9upcAZzBw0CEVWwXP"
      )
      .then(
        (result) => {
          alert(
            "Your message has been sent successfullt. We will get back to you soon."
          );
        },
        (error) => {
          alert("Something went wrong. Please try again later");
        }
      );
  };
  return (
    <section className="contact__section" id="enquiry-form">
      <div className="container" style={{ marginTop: "0", paddingTop: "4em" }}>
        <h3>Enquire Now</h3>
        <p>
          Please fill in this form to register your interest and I'll be in
          touch soon!
        </p>
        <div className="form-section">
          <form action="" ref={form} onSubmit={sendEmail}>
            <div className="form--grid">
              <div className="input-group">
                <input type="text" placeholder="First Name *" name="fname" />
              </div>
              <div className="input-group">
                <input type="text" placeholder="Last Name *" name="lname" />
              </div>
              <div className="input-group">
                <input
                  type="email"
                  placeholder="Email Address *"
                  name="email"
                />
              </div>
              <div className="input-group">
                <input type="text" placeholder="Phone Number *" name="phone" />
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Organization Name"
                  name="organization"
                />
              </div>
              <div className="input-group">
                <span className="hidden-placeholder">Select Event Date</span>
                <input
                  type="date"
                  placeholder="Select Event Date"
                  name="date"
                />
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Number of Participants"
                  name="numberOfParticipants"
                />
              </div>
              <div className="input-group">
                <input type="text" placeholder="Event Name" name="event_name" />
              </div>
            </div>

            <div className="input-group">
              <textarea
                name="message"
                id=""
                cols="30"
                rows="5"
                placeholder="Send a message"
              ></textarea>
            </div>
            <div className="primary-btn" style={{ marginTop: "1em" }}>
              <a href="#!">Submit</a>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Enquireform;
